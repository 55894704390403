import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  storedFilters: {},
  currentProvince: '',
  msaPhase: 0,
  msaPause: null,
};

export const filtersSlice = createSlice({
  name: 'Filters',
  initialState,
  reducers: {
    updateStoredFilters: (state, action) => {
      state.storedFilters = { ...action.payload };
    },
    updateCurrentProvince: (state, action) => {
      state.currentProvince = action.payload;
    },
    updateMsaPhase: (state, action) => {
      state.msaPhase = action.payload;
    },
    resetMsaPhase: (state) => {
      state.msaPhase = 0;
    },
    updateMsaPause: (state, action) => {
      state.msaPause = action.payload;
    },
    resetMsaPause: (state) => {
      state.msaPause = null;
    },
  },
});

export const {
  updateStoredFilters,
  updateCurrentProvince,
  updateMsaPhase,
  resetMsaPhase,
  updateMsaPause,
  resetMsaPause,
} = filtersSlice.actions;

export default filtersSlice.reducer;

const getActivePhones = (phones = []) => phones
  ?.filter(({ isPhoneActive }) => isPhoneActive)
  ?.map(({ phoneVariants, ...phone }) => ({
    ...phone,
    phoneVariants: getActivePhoneVariants(phoneVariants),
  }))
  ?.filter(({ phoneVariants }) => phoneVariants?.length);

export const getActivePhoneVariants = (phoneVariants = []) => phoneVariants
  ?.filter(({ isPhoneVariantActive }) => isPhoneVariantActive);

export default getActivePhones;

import { upperFirst, orderBy, minBy } from 'lodash';

const findSome = (list) => list.options.some((option: Record<string, unknown>) => option.enabled);

export const updateAccessoryFilters = (
  items: Record<string, any>[],
  filters: Record<string, any>[],
  sorters: Record<string, any>[],
  selectedPhone: Record<string, any> | null,
): Record<string, any> => {
  // Getting list of enabled filters and sorters
  const enabledFilters = filters.filter((filter) => findSome(filter));
  const enabledSorters = sorters.filter((sorter) => findSome(sorter));

  // Applying filters and sorters to the list of items
  const filteredItems = items.filter((item) => enabledFilters.every((filter) => filter.options
    .filter((option: Record<string, any>) => option.enabled)
    .some((option: Record<string, any>) => applyFilter(item, filter.id, option, selectedPhone))));

  const sortedItems = enabledSorters.reduce((acc, curr) => {
    const enabledOption = curr.options.find(
      (option: Record<string, any>) => option.enabled,
    );
    return applySort(acc, enabledOption.key);
  }, filteredItems);

  return { sortedItems };
};

// Function to apply Filters based upon type of filters selected
const applyFilter = (
  item: Record<string, any>,
  id: string,
  option: Record<string, any>,
  selectedPhone: Record<string, any> | null,
) => {
  switch (id) {
    case 'type':
      return item?.accessory_category?.some(
        ({ categoryName }) => upperFirst(categoryName) === option.name,
      );

    case 'colour':
      return item?.variants
        .filter((eachItem: Record<string, any>) => eachItem)
        .some(
          ({ accessoryColour }) => upperFirst(accessoryColour?.displayName?.trim()) === option.name,
        );

    case 'brand':
      return item?.manufacturerName?.trim() === option.name;

    case 'phone':
      return item?.compatiblePhones?.some(
        (eachItem: Record<string, any>) => eachItem.contentful_id === selectedPhone?.contentful_id,
      );

    default:
      return false;
  }
};

type Item = Record<string, string | number | Date>;

// Function to apply sort based upon type of sort selected
const applySort = (items: Record<string, any>, sortType: string) => {
  switch (sortType) {
    case 'top-selling':
      return items.sort(
        (a: Record<string, any>, b: Record<string, any>) => b.unitsSold - a.unitsSold,
      );

    case 'newest':
      return items.sort(
        (a: Item, b: Item) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
      );

    case 'price-high-low':
      return orderBy(items, ({ variants }) => minBy(variants, 'price')?.price, [
        'desc',
      ]);

    case 'price-low-high':
      return orderBy(items, ({ variants }) => minBy(variants, 'price')?.price, [
        'asc',
      ]);
    case 'alphabetical-ascending':
      return orderBy(items, ['manufacturerName'], ['asc']);
    default:
      return [];
  }
};

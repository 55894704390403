import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showKioskSetup: false,
  storeCategories: [],
};

export const sharedSlice = createSlice({
  name: 'Shared',
  initialState,
  reducers: {
    updateShowKioskSetup: (state, action) => {
      state.showKioskSetup = action.payload;
    },
    updateStoreCategories: (state, action) => {
      state.storeCategories = action.payload;
    },
  },
});

export const { updateShowKioskSetup, updateStoreCategories } = sharedSlice.actions;

export default sharedSlice.reducer;

import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';
import cartReducer from './reducers/cartReducer';
import phoneReducer from './reducers/phoneReducer';
import filtersReducer from './reducers/filtersReducer';
import sharedReducer from './reducers/sharedReducer';
import accessoryFiltersReducer from './reducers/accessoryFiltersReducer';

// Generate Root Reducer. Any new reducers should be passed to `combineReducers`
const rootReducer = combineReducers({
  cartReducer,
  phoneReducer,
  filtersReducer,
  sharedReducer,
  accessoryFiltersReducer,
});

export default () => {
  const store = configureStore({
    reducer: rootReducer,
  });
  return { store };
};

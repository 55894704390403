import { createSlice } from '@reduxjs/toolkit';
import { ItemType } from '../../types';

const initialState = {
  items: [] as any[],
  store: {
    // TODO Add `allStoreLogos` to redux state instead of context
    selectedStore: null,
    selectedStoreChanged: false,
  },
  showAddToCartTooltip: false,
  zendeskConfirmationId: null,
  userPiData: null,
};

export const cartSlice = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const payload = createReduxPayload(action.payload);
      const itemIndex = state.items.findIndex(
        (item) => item.productSku === payload?.productSku,
      );
      if (itemIndex === -1) {
        state.items = [...state.items, payload];
      } else {
        state.items = state.items.map((item, index) => ({
          ...item,
          quantity: item.quantity + (index === itemIndex),
        }));
      }
    },
    updateItemQuantity: (state, action) => {
      state.items = state.items.map((item) => ({
        ...item,
        quantity:
          item.productSku === action.payload.productSku
            ? action.payload.newQuantity
            : item.quantity,
      }));
    },
    updateCartWithInventoryStatus: (state, action) => {
      state.items.forEach((cartItem) => {
        const { status } = action.payload.find(
          (item) => item.productSku === cartItem.productSku,
        );
        cartItem.inventoryStatus = status;
      });
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(
        (_, index) => index !== action.payload.index,
      );
      if (!state.items.length) state.store.selectedStoreChanged = false;
    },
    emptyCart: (state) => {
      state.userPiData = null;
      state.items = [];
    },
    updateSelectedStore: (state, action) => {
      if (
        state.items.length
        && state.store.selectedStore
        && (state.store.selectedStore as any).commonInfo.lclId
          !== action.payload.commonInfo.lclId
      ) {
        state.store.selectedStoreChanged = true;
      } else {
        state.store.selectedStoreChanged = false;
      }
      state.store.selectedStore = action.payload;
    },
    updateShowAddToCartTooltip: (state, action) => {
      state.showAddToCartTooltip = action.payload;
    },
    updateZendeskId: (state, action) => {
      state.zendeskConfirmationId = action.payload;
    },
    updateUserPiData: (state, action) => {
      state.userPiData = action.payload;
    },
    removeUserPiData: (state) => {
      state.userPiData = null;
    },
  },
});

const createReduxPayload = (item) => {
  switch (item.__typename) {
    case 'ContentfulAccessoryVariant':
      return {
        id: item.iqCatalogItemId,
        itemType: ItemType.Accessory,
        image: item?.imageLinksList?.[0],
        itemName: item.displayName,
        itemSlug: item.accessory[0]?.urlSlug,
        costDetails: {
          totalCost: item.price,
          pcoPoints: item.pcoPoints,
        },
        productDetails: {
          dpp: {
            dppFlag: false,
          },
          deviceTradeIn: null,
        },
        productSku: item.productSku,
        quantity: 1,
      };
    case 'ContentfulPhoneVariant':
      return {
        id: item.sku,
        itemType: ItemType.Phone,
        deviceType: item.deviceType,
        image: item.phoneBrand.oemPhoneFrontImage.asset.url,
        carrierName: item.phoneCarrier.cmsName,
        itemName: item.cmsName,
        itemSlug: item.phone[0]?.urlSlug,
        costDetails: {
          carrier: item.phoneCarrier.logo.asset.url,
          upfrontCost: item.carrierUpfrontPrice,
          monthlyCost: item.monthlyPrice,
          pcoPoints: item.pcoPoints,
        },
        productDetails: {
          // TODO: FInd out a way to remove dppRequested key in BE and use dppFlag to avoid redundancy
          dpp: {
            dppFlag: item.dpp.dppSelected,
            dppRequested: item.dpp.dppSelected ? 'Yes' : 'No',
            retailPrice: item?.dpp?.dppResponse?.price,
            monthlyFee: item?.dpp?.dppResponse?.monthlyFee,
            replacementFee: item?.dpp?.dppResponse?.replacementFee,
          },
          // TODO: Check if we need to send pco points to our BE as string or number
          deviceTradeIn: item?.tradeIn
            ? {
              trade_in_imei: item?.tradeIn?.IMEInumber,
              trade_in_model: item?.tradeIn?.userPhone?.phone,
              trade_in_quote: item?.tradeIn?.pointsValue,
            }
            : null,
        },
        productSku: item.sku,
        reserveStatus: item.reserveStatus,
        quantity: 1,
      };
    default:
      return null;
  }
};

export const {
  addToCart,
  updateItemQuantity,
  removeFromCart,
  emptyCart,
  updateSelectedStore,
  updateShowAddToCartTooltip,
  updateCartWithInventoryStatus,
  updateZendeskId,
  updateUserPiData,
  removeUserPiData,
} = cartSlice.actions;

export default cartSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  phobioDetails: null,
  phonesCount: 0,
};

export const cartSlice = createSlice({
  name: 'Phone',
  initialState,
  reducers: {
    addPhobioDetails: (state, action) => {
      state.phobioDetails = action.payload;
    },
    removePhobioDetails: (state) => {
      state.phobioDetails = null;
    },
    setPhonesCount: (state, action) => {
      state.phonesCount = action.payload;
    },
  },
});

export const { addPhobioDetails, removePhobioDetails, setPhonesCount } = cartSlice.actions;

export default cartSlice.reducer;

import { createAccessoryFilterOptions } from './createAccessoryFilterOptions';
import { updateAccessoryFilters } from './updateAccessoryFilters';

export const createAccessoryFilters = (
  filterNames: Record<string, any>[],
  items: Record<string, any>[],
  phones: Record<string, any>[],
): Record<string, any> => {
  // Creating list of filters and sorters in this section
  const filters = filterNames.reduce<Record<string, any>[]>((acc, filterName) => {
    if (filterName.fieldName !== 'sort') {
      acc.push({
        id: filterName.fieldName,
        name: filterName.displayLabel,
        type: 'checkbox',
        options: createAccessoryFilterOptions(filterName?.fieldName, items, phones),
      });
    }
    return acc;
  }, []);

  const sorters = filterNames.reduce<Record<string, any>[]>((acc, sorter) => {
    if (sorter.fieldName === 'sort') {
      acc.push({
        id: sorter.fieldName,
        name: sorter.displayLabel,
        type: 'radio',
        options: createAccessoryFilterOptions(
          sorter?.fieldName,
          sorter?.fieldContent,
        ),
      });
    }
    return acc;
  }, []);

  return {
    ...updateAccessoryFilters(items, filters, sorters, null),
    filters,
    sorters,
  };
};
